<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 300px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['学习开始时间', '结束时间']"
            style="width: 300px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 150px"
            v-model="page.video_learn_state"
            placeholder="请选择完成状态"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">已完成</a-select-option>
            <a-select-option value="2">未完成</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
<!--        <a-form-item>-->
<!--          <a-button-->
<!--            :icon="getExicon.icon"-->
<!--            :disabled="getExicon.ed"-->
<!--            @click="$exportFun('/export/videoLearnLog', page)"-->
<!--            >导出</a-button-->
<!--          >-->
<!--        </a-form-item>-->
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="project_in_id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
      >
        <template slot="state" slot-scope="text, record">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="sex" slot-scope="text, record">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="orange"> 审核中 </a-tag>
          <a-tag v-if="text == '2'" color="green"> 已通过 </a-tag>
          <a-tag v-if="text == '3'" color="red"> 已拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text, record">
          <view-file :data="text"></view-file>
        </template>
        <template slot="video_learn_state" slot-scope="text">
          <a-tag color="red" v-if="text == 1">未完成</a-tag>
          <a-tag color="green" v-if="text == 2">已完成</a-tag>
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="switcher"
            @click="() => openeDetail(record['project_in_id'])"
            >查看详情</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      title="查看详情"
      width="1100px"
      :visible="vLogVisible"
      @ok="vLogClose"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vLogClose"
      :confirmLoading="$store.state.requestLoading"
    >
      <div style="margin-bottom: 15px; text-align: right">
        <a-button
          :icon="getExicon.icon"
          :disabled="getExicon.ed"
          @click="$exportFun('/export/videoLearnLogList', vLogPage)"
          >导出</a-button
        >
      </div>
      <a-table
        :columns="vLogColumns"
        :data-source="vLogList"
        :pagination="{
          total: vLogPage.totalRow,
          defaultPageSize: vLogPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
      </a-table>
    </a-modal>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      tableHeigt: 0,
      vLogVisible: false,
      vLogColumns: [
        {
          title: "姓名",
          dataIndex: "name",
        },
        {
          title: "所属项目",
          dataIndex: "pname",
        },
        {
          title: "工种",
          dataIndex: "wname",
        },
        {
          title: "视频标题",
          dataIndex: "vtitle",
        },
        {
          title: "累计观看秒数",
          dataIndex: "total_learn_second",
        },
        {
          title: "学习时间",
          dataIndex: "start_time",
        },
        // {
        //   title: "最后一次学习时间",
        //   dataIndex: "last_up_time",
        // },
      ],
      vLogList: [],
      vLogPage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        project_in_id: "",
        video_learn_state: undefined,
      },
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      viewImgData: "",
      examineData: {
        id: "",
        audit_state: undefined,
        refuse_reason: "",
      },
      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        // {
        //   title: "视频标题",
        //   dataIndex: "vtitle",
        //   width: 300,
        // },
        {
          title: "累计观看秒数",
          dataIndex: "totals",
          width: 140,
          align: "center",
        },

        // {
        //   title: "最后一次学习时间",
        //   dataIndex: "last_up_time",
        //   width: 160,
        // },
        {
          title: "姓名",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "身份证号",
          dataIndex: "idno",
        },
        // {
        //   title: "手机号码",
        //   dataIndex: "mobile",
        //   width: 180,
        // },
        // {
        //   title: "出生日期",
        //   dataIndex: "birthday_time",
        //   width: 140,
        // },
        // {
        //   title: "登录密码",
        //   dataIndex: "login_pwd",
        // },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
          align: "center",
        },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        // {
        //   title: "创建时间",
        //   dataIndex: "create_time",
        //   width: 180,
        // },
        // {
        //   title: "应急联系人",
        //   dataIndex: "contact_name",
        //   width: 140,
        // },
        // {
        //   title: "应急联系人手机号码",
        //   dataIndex: "contact_mobile",
        //   width: 180,
        // },
        {
          title: "民族",
          dataIndex: "national",
          width: 80,
          align: "center",
        },

        {
          title: "完成状态",
          dataIndex: "video_learn_state",
          scopedSlots: { customRender: "video_learn_state" },
          width: 90,
          align: "center",
        },
        {
          title: "学习时间",
          dataIndex: "start_time",
          width: 180,
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        project_id: undefined,
        start_time: "",
        end_time: "",
        work_id: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 查看详情弹框
    async openeDetail(id) {
      this.vLogPage.project_in_id = id;
      let res = await Api.Detail({ ...this.vLogPage });
      if (res && res["code"] == "0") {
        this.vLogList = res.page.list;
      } else {
        this.$message.error(res.message);
      }
      this.vLogVisible = true;
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          this.resetPwd(id);
        },
        onCancel() {},
      });
    },

    async resetPwd(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    hideModal() {
      this.imgvisible = false;
    },
    // 审核弹框
    async openexamineData(id) {
      this.examineData.id = id;
      this.examineVisible = true;
    },
    /**
     * 审核
     *
     */
    vLogClose() {
      this.vLogVisible = false;
    },
    // 关闭窗口
    cancel() {
      this.examineVisible = false;
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
  },
};
</script>

<style scoped>
</style>

